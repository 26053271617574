import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';

import CustomPrimaryButton from '../../components/CustomPrimaryButton';
import Metatags from '../../components/Metatags';
import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const PageNotFound = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const NotFound = () => {
    return (
      <Box className={classes.notFoundWrapper}>
        <Box className={classes.notFoundContentWrapper}>
          <Box className={classes.notFoundContent}>
            <Box className={classes.vectorImgBox}>
              <img
                className={classes.vectorImg}
                src={URL_IMAGES.VECTOR_ALIEN}
                alt='Project managnmet life cicle'
              />
            </Box>
            <h2 className={clsx(fontClasses.h2, classes.textAlign)}>Nothing Around Here</h2>
            <p className={clsx(fontClasses.p, classes.textAlign)}>
              We couldn&apos;t find this page.
              <br />
              Don&apos;t worry! You can explore our services in the button below.
            </p>
            <Box className={classes.headerButtonsBox}>
              <CustomPrimaryButton
                label='Explore Our Services'
                url={'/services'}
                icon={<ArrowForwardIcon className={classes.buttonIcon} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies | Page not found'
        description="We can't find this page"
      />
      <NotFound />
    </Box>
  );
};

export default PageNotFound;
