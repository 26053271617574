export const URL_LOGO_SEALABS =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/logo_sealabs.png';
export const URL_LOGO_SEALABS_WHITE =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/logo_selabs_white.png';
export const URL_LOGO_SEALABS_BLUE =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/logo_selabs_blue.png';
export const URL_LOGO_SEALABS_DARK =
  'https://res.cloudinary.com/sealabs/image/upload/v1664661766/logos/logo_selabs_dark_if4ycw.png';

export const URL_IMG_LANDINGPAGE_HEADER =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/background_header.webp';
export const VECTOR_PHONE =
  'https://res.cloudinary.com/sealabs/image/upload/v1664666716/vectors/iphone12-shadow-crop.png';
export const VECTOR_PHONE2 =
  'https://res.cloudinary.com/sealabs/image/upload/v1665623279/vectors/iphone12-shadow-crop-2.png';
export const URL_IMG_SECTION_HEADER =
  'https://res.cloudinary.com/sealabs/image/upload/v1665961253/vectors/section_background_header_vypcm0.png';
export const VECTOR_PM_SECTION =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/vector_project_managment.webp';
export const VECTOR_PM_SECTION_VERTICAL =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/vector_project_managment_vertical.webp';
export const VECTOR_ST_LOCATION_MAP =
  'https://res.cloudinary.com/sealabs/image/upload/v1665870310/vectors/bg_location_ugiygr.png';
export const VECTOR_DEVICES_WHITE =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/vector_devices_white.webp';
export const VECTOR_TECH_HORIZONTAL =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/vector_technologies_horizontal.png';
export const VECTOR_TECH_VERTICAL =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/vector_technologies_vertical.png';
export const VECTOR_ALIEN =
  'https://raw.githubusercontent.com/sealabscr/public-assets/main/sealabs.cr/alien_vector.png';

export const TEAM_MEMBER_JOSS =
  'https://res.cloudinary.com/sealabs/image/upload/v1665959733/team_images/team_member_joss_nwekfb.jpg';
export const TEAM_MEMBER_JEAN =
  'https://res.cloudinary.com/sealabs/image/upload/v1693362543/team_images/IMG_5750_dsscv5.jpg';
export const TEAM_MEMBER_SAMUEL =
  'https://res.cloudinary.com/sealabs/image/upload/v1665959734/team_images/tem_member_samuel_szobou.jpg';
export const TEAM_MEMBER_AXEL =
  'https://res.cloudinary.com/sealabs/image/upload/v1665959733/team_images/tem_member_axel_y4g73p.jpg';
