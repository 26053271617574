import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  cardBox: {
    width: '100%',
    background: '#F1F9F9',
    border: 'solid 1px #F1F9F9',
    borderRadius: '10px',
    height: '100%',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
  imageBox: {
    width: '100%',
    height: '350px',
    [themeBreakpoints.down('md')]: {
      height: '450px',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  positionText: {
    fontWeight: 600,
    color: '#4EADB3',
  },
  contentBox: {
    padding: '20px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  descriptionBox: {
    marginTop: '10px',
    textAlign: 'justify',
  },
  linkedInIcon: {
    fontSize: '28px!important',
    marginTop: '20px',
    color: '#151B25',
    '&:hover': {
      color: '#4EADB3',
    },
  },
  socialIcon: {
    marginTop: '15px',
    marginBottom: '0px',
    color: '#151B25',
    '&:hover': {
      textDecoration: 'none',
      color: '#4EADB3',
    },
  },
}));

export default useStyles;
