import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import { useEffect } from 'react';

import CustomPrimaryButton from '../../components/CustomPrimaryButton';
import Metatags from '../../components/Metatags';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Careers = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.careerWrapper}>
        <Box className={classes.careerContentWrapper}>
          <Box>
            <h2 className={fontClasses.h2}>Current Job Openings</h2>
            <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
              Feel like you fit in Sealabs? Explore our career opportunities and join us.
            </p>
            <Box className={classes.headerButtonsBox}>
              <CustomPrimaryButton
                label='Linkedin'
                url={'https://www.linkedin.com/company/sealabscr/'}
                icon={<LinkedInIcon className={classes.buttonIcon} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const FormComponent = () => {
    return (
      <Box className={classes.formWrapper}>
        <Box className={classes.formContentWrapper}>
          <Box className={classes.searchComponent}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl variant='standard' sx={{ minWidth: 120, width: '100%' }}>
                  <InputLabel id='demo-simple-select-standard-label'>Country</InputLabel>
                  <Select
                    labelId='id-country-label'
                    id='id-country-label'
                    value={1}
                    label='Age'
                    style={{ marginTop: '20px' }}
                  >
                    <MenuItem value={1}>Costa Rica</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl variant='standard' sx={{ minWidth: 120, width: '100%' }}>
                  <InputLabel id='demo-simple-select-standard-label'>Department</InputLabel>
                  <Select
                    labelId='id-country-label'
                    id='id-country-label'
                    value={1}
                    label='Age'
                    style={{ marginTop: '20px' }}
                  >
                    <MenuItem value={1}>Technology</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl variant='standard' sx={{ minWidth: 120, width: '100%' }}>
                  <InputLabel id='demo-simple-select-standard-label'>Location</InputLabel>
                  <Select
                    labelId='id-country-label'
                    id='id-country-label'
                    value={1}
                    label='Age'
                    style={{ marginTop: '20px' }}
                  >
                    <MenuItem value={1}>Remote</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Box className={classes.filterBox}>
                  <p className={clsx(fontClasses.p, classes.filterButton)}>Clear filters</p>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.resultsBox}>
            <Box className={classes.insideContent}>
              <p className={clsx(fontClasses.p, classes.resultText)}>
                There are <b>no jobs available</b> at the moment.
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies | Careers'
        description='Feel like you fit in Sealabs? Explore our career opportunities and join us.'
      />
      <Header />
      <FormComponent />
    </Box>
  );
};

export default Careers;
