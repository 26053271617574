import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DevicesIcon from '@mui/icons-material/Devices';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WebIcon from '@mui/icons-material/Web';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';

import CustomPrimaryButton from '../../components/CustomPrimaryButton';
import CustomSecondaryButton from '../../components/CustomSecondaryButton';
import ItemWithIcon from '../../components/ItemWithIcon';
import Metatags from '../../components/Metatags';
import { URL_IMAGES } from '../../constants';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const Home = () => {
  const classes = styles();
  const fontClasses = fontsStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const Header = () => {
    return (
      <Box className={classes.headerWrapper}>
        <Box className={classes.headerContentWrapper}>
          <Box className={classes.headerContent}>
            <h1 className={fontClasses.h1}>
              Improve Your Business <br /> Through Digital <br /> Transformation{' '}
            </h1>
            <p className={clsx(fontClasses.p, classes.headerSubtitle)}>
              We build personalized software solutions to suit your business needs ensuring quality
              and transparency.
            </p>
            <Box className={classes.headerButtonsBox}>
              <CustomPrimaryButton
                label='Explore Our Services'
                url={'/services'}
                icon={<ArrowForwardIcon className={classes.buttonIcon} />}
              />
              <CustomSecondaryButton
                label='Contact Us'
                url={'/contact'}
                icon={<ArrowForwardIcon className={classes.buttonIconSecondary} />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const BussinesProblems = () => {
    return (
      <Box className={classes.bussinesWrapper}>
        <Box className={classes.bussinesContentWrapper}>
          <Box className={classes.bussinesHeaderContent}>
            <h2 className={fontClasses.h2}>We Solve Business Problems</h2>
            <p className={clsx(fontClasses.p, fontClasses.titleInfo)}>
              At Sealabs we try to take our clients ideas to help them innovate their business and
              establish themselves in the digital world.{' '}
              <a className={fontClasses.a} href='/services'>
                Explore our services
              </a>
            </p>
          </Box>
          <Grid container columnSpacing={12} rowSpacing={{ xs: 5, sm: 5, md: 10, lg: 10 }}>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'Websites'}
                content={
                  'We promote your business idea by developing a customized website that can be adapted to all types of devices.'
                }
                bgColor='#F1F9F9'
                icon={<WebIcon style={{ color: '#4EADB3' }} />}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'Web/Mobile apps'}
                content={
                  'We develop web or mobile applications according to your needs to optimize your customers and employees’ tasks.'
                }
                bgColor='#FFF9ED'
                icon={<DevicesIcon style={{ color: '#FFA81A' }} />}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <ItemWithIcon
                title={'E-commerce Solutions'}
                content={
                  'We promote your business idea by developing a customized website that can be adapted to all types of devices.'
                }
                bgColor='#FFF5F6'
                icon={<LocalMallIcon style={{ color: '#FF7994' }} />}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'none', xl: 'none' } }}
            >
              <ItemWithIcon
                title={'Consulting Services'}
                content={
                  'Provide solutions for Information Technology services problems a business can face in maintenance, development, and management.'
                }
                bgColor='#EEF7FE'
                icon={<QuestionAnswerIcon style={{ color: '#0077FF' }} />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const ProjectManagement = () => {
    return (
      <Box className={classes.projectMangWrapper}>
        <Box className={classes.projectMangContentWrapper}>
          <Box className={classes.insideContent}>
            <Box className={classes.pmText}>
              <h2 className={fontClasses.h2} style={{ textAlign: 'center' }}>
                Project Managment Life Cicle
              </h2>
              <p className={clsx(fontClasses.p, classes.pargrahpCenter)}>
                We will help your business grow by creating customizable and high-quality software
                for an efficient streamlining of processes.
              </p>
            </Box>
            <Box
              sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}
            >
              <Box className={classes.pmImage}>
                <img
                  style={{ width: '80%' }}
                  src={URL_IMAGES.VECTOR_PM_SECTION}
                  alt='Project managnmet life cicle'
                />
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' } }}>
              <Box className={classes.pmImage}>
                <img
                  style={{ width: '80%' }}
                  src={URL_IMAGES.VECTOR_PM_SECTION_VERTICAL}
                  alt='Project managnmet life cicle'
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const StrategicLocation = () => {
    return (
      <Box className={classes.strategicLocationWrapper}>
        <Box className={classes.strategicLocationContentWrapper}>
          <Box className={clsx(classes.insideContent, classes.strategicLocationContent)}>
            <Box>
              <h2 className={clsx(fontClasses.h2, classes.stLocationTitleText)}>
                We Are Located In A Strategic Location
              </h2>
              <p className={clsx(fontClasses.p, classes.stLocationText)}>
                We choose Limon as our location because we are willing to develop the technology
                industry around the city, involving small businesses to help them grow up and create
                a chain of processes for ou clients to improve their efficiency.{' '}
                <a className={fontClasses.aGreen} href='/about'>
                  Read more about us
                </a>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const WorkWithUs = () => {
    return (
      <Box className={classes.workWithUsWrapper}>
        <Box className={classes.workWithUsContentWrapper}>
          <Box>
            <Grid container>
              <Grid item md={7} sm={12}>
                <Box>
                  <h1 className={clsx(fontClasses.h2)}>Start Working With Us </h1>
                  <p className={clsx(fontClasses.p)}>
                    Our main goal is to provide our clients with the best service performance and
                    come up with solutions for their digital transformation.
                  </p>
                </Box>
                <Box className={classes.headerButtonsBox}>
                  <CustomPrimaryButton
                    label='Contact Us'
                    url={'/contact'}
                    icon={<ArrowForwardIcon className={classes.buttonIcon} />}
                  />
                  <CustomSecondaryButton
                    label='Linkedin'
                    url={'https://www.linkedin.com/company/sealabscr/'}
                    icon={<LinkedInIcon className={classes.buttonIconSecondary} />}
                  />
                </Box>
              </Grid>
              <Grid
                md={1}
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
              />
              <Grid
                item
                md={4}
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '100%' }}
                    src={URL_IMAGES.VECTOR_DEVICES_WHITE}
                    alt='Project managnmet life cicle'
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Metatags
        title='Sealabs Technologies'
        description='We build personalized software solutions to suit your business needs ensuring quality and transparency.'
      />
      <Header />
      <BussinesProblems />
      <StrategicLocation />
      <ProjectManagement />
      <WorkWithUs />
    </Box>
  );
};

export default Home;
