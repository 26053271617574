// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

// import breakpoints from '../../theme/breakpoints';

// const themeBreakpoints = createBreakpoints(breakpoints);

const useStyles = makeStyles((theme) => ({
  buttonMainStyle: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: '#151B25',
    width: '100%',
    padding: '10px 20px',
    height: '45px',
    border: 'solid 2px #151B25',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  iconButtonBox: {
    padding: '5px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: 'splid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
