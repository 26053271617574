import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import fontsStyles from '../../styles/fontStyles';
import useStyles from './styles';

const TeamCard = ({ name, image, title, content, linkedIn }) => {
  const fontClasses = fontsStyles();
  const classes = useStyles();

  return (
    <Box className={classes.cardBox}>
      <Box className={classes.imageBox}>
        <img className={classes.img} src={image} alt='Team'></img>
      </Box>
      <Box className={classes.contentBox}>
        <Box>
          <h4 className={fontClasses.teamTitle}>{name}</h4>
          <p className={clsx(fontClasses.p, classes.positionText)}>{title}</p>
          <a href={linkedIn} target='_blank' rel='noreferrer'>
            <p className={clsx(classes.socialIcon)}>
              <LinkedInIcon style={{ fontSize: '30px' }} />
            </p>
          </a>
        </Box>
        {/*
          <Box className={classes.descriptionBox}>
              <p className={clsx(fontClasses.p, classes.contentText)}>{content}</p>
          </Box>
          */}
      </Box>
    </Box>
  );
};

export default TeamCard;
