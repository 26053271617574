import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { DIMENSIONS, URL_IMAGES } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundColor: '#EEF7FE',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '550px',
    paddingTop: '50px',
    [themeBreakpoints.down('md')]: {
      backgroundOrigin: 'content-box',
      backgroundImage: `url(${URL_IMAGES.VECTOR_PHONE2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right bottom',
      backgroundSize: 'contain',
    },
    [themeBreakpoints.down('sm')]: {
      backgroundImage: `none`,
      height: '100%',
    },
  },
  headerContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${URL_IMAGES.VECTOR_PHONE})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: 'contain',
    height: '550px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      backgroundImage: 'none',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      height: '100%',
      marginBottom: '100px',
    },
  },
  headerContent: {
    marginBottom: '80px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '0px',
    },
  },
  headerButtonsBox: {
    display: 'flex',
    gap: 20,
    marginTop: '30px',
    flexWrap: 'wrap',
    [themeBreakpoints.down('sm')]: {
      gap: 10,
    },
  },
  headerSubtitle: {
    width: '70%',
    [themeBreakpoints.down('md')]: {
      width: '60%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonIcon: {
    fontSize: '20px!important',
    color: 'white',
    [themeBreakpoints.down('sm')]: {
      fontSize: '16px!important',
    },
  },
  buttonIconSecondary: {
    fontSize: '20px!important',
    color: '#151B25',
    [themeBreakpoints.down('sm')]: {
      fontSize: '18px!important',
    },
  },
  bussinesWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  bussinesContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '150px',
    marginBottom: '150px',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      marginTop: '100px',
      marginBottom: '100px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  bussinesHeaderContent: {
    marginBottom: '80px',
    [themeBreakpoints.down('sm')]: {
      marginBottom: '50px',
    },
  },
  projectMangWrapper: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  projectMangContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '75px',
    marginBottom: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.SUB_TABLET_WRAPPER,
      marginTop: '50px',
      marginBottom: '200px',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '50px',
      marginBottom: '150px',
    },
  },
  insideContent: {
    width: DIMENSIONS.SUB_WRAPPER,
    [themeBreakpoints.down('md')]: {
      width: '90%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  pmText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  pargrahpCenter: {
    textAlign: 'center',
    width: '80%',
    [themeBreakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  pmImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '80px',
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  strategicLocationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  strategicLocationContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '100px',
    marginBottom: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F9F9',
    borderRadius: '20px',
    backgroundImage: `url(${URL_IMAGES.VECTOR_ST_LOCATION_MAP})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '105%, center',
    backgroundSize: 'contain',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      backgroundImage: 'none',
      marginTop: '50px',
      marginBottom: '100px',
    },
    [themeBreakpoints.down('sm')]: {
      backgroundImage: 'none',
      width: '90%',
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  strategicLocationContent: {
    marginTop: '80px',
    marginBottom: '80px',
    [themeBreakpoints.down('md')]: {
      marginTop: '50px',
      marginBottom: '50px',
    },
    [themeBreakpoints.down('sm')]: {
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
  stLocationTitleText: {
    [themeBreakpoints.down('md')]: {
      textAlign: 'center',
    },
    [themeBreakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  stLocationText: {
    width: '65%',
    textAlign: 'justify',
    [themeBreakpoints.down('md')]: {
      width: '100%',
    },
    [themeBreakpoints.down('sm')]: {
      width: '100%',
    },
  },
  workWithUsWrapper: {
    backgroundColor: '#FFF9ED',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [themeBreakpoints.down('sm')]: {
      height: '100%',
    },
  },
  workWithUsContentWrapper: {
    width: DIMENSIONS.MAIN_WRAPPER,
    marginTop: '150px',
    marginBottom: '150PX',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [themeBreakpoints.down('md')]: {
      width: DIMENSIONS.TABLET_WRAPPER,
      backgroundImage: 'none',
      marginTop: '100px',
      marginBottom: '100PX',
    },
    [themeBreakpoints.down('sm')]: {
      width: '90%',
      marginTop: '80px',
      marginBottom: '80px',
    },
  },
}));

export default styles;
